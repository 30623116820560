import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import CategoryList from "../components/category-list"
import ArchiveMonthlyList from "../components/archive-monthly-list"

import { rhythm } from "../utils/typography"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <Header />
    )
  } else {
    header = (
      <Header />
    )
  }
  return (
    <div>
      <div className="header-pane">{header}</div>
      <div className="body-pane">
        <section className="main-pane" style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(0.15)} 0px ${rhythm(1 / 3)} ${rhythm(1 / 3)}`,
          }}
        >
          <main>{children}</main>
        </section>
        <section className="side-pane">
          <CategoryList />
          <ArchiveMonthlyList />
        </section>
      </div>

      <div className="footer-pane">
        <Footer />
      </div>
    </div>
  )
}

export default Layout
