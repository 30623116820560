import React from "react"
const Footer = () => {
    return (
        <footer id="footer">
            <div className="lower">
                <div className="inr">
                    <ul className="lower-link">
                        <li><a href="http://www.infocom.co.jp/privacy/">個人情報保護方針</a></li>
                        <li><a href="http://www.infocom.co.jp/sitepolicy.html">サイトのご利用について</a></li>
                        <li><a href="http://www.infocom.co.jp/socialmedia.html">ソーシャルメディア公式アカウント一覧</a></li>
                    </ul>
                    <p className="copyright">Copyright © INFOCOM CORPORATION All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
