import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../utils/typography"
import background from "../../content/images/header_bg.png"

const Header = () => {
    const data = useStaticQuery(graphql`
        query HeaderQuery {
            header_logo: file(absolutePath: { regex: "/sp_hd_logo.png/" }) {
                childImageSharp {
                    fixed(width: 330) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            site {
                siteMetadata {
                  title
                  description
                  siteUrl
                }
              }
        }
    `)
    const { title, siteUrl } = data.site.siteMetadata
    return (
        <header id="head" style={{ 
                backgroundImage: `url(${background})`,
                width: `100%`,
                height: `100px`,
            }}>
            <div className="logo">
                <h1>
                    <a href={`${siteUrl}`}>
                        <Image
                            fadeIn={false}
                            fixed={data.header_logo.childImageSharp.fixed}
                            style={{
                                marginRight: rhythm(1 / 2),
                                marginBottom: 0,
                            }}
                            alt={title}
                        />
                    </a>
                </h1>
            </div>
        </header>
    )
}

export default Header
