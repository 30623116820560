import React from "react"

// Components
import { Link, StaticQuery, graphql } from "gatsby"

const ArchiveMonthlyList = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___date) {
            fieldValue
            totalCount
          }
        }
      }
    `}
    render={data => {
        const monthGrouppedData = data.allMarkdownRemark.group;
        let counts = {}
        let monthValue = []
  
        for(let i = 0; i< monthGrouppedData.length; i++){
          let d = new Date(monthGrouppedData[i].fieldValue)
          let year = d.getFullYear();
          let month = (d.getMonth()+1).toString().padStart(2, "0");
          monthValue.push(`${year}/${month}`);
        }
  
        monthValue.forEach(function(x) { 
          counts[x] = (counts[x] || 0)+1; 
        });
  
        counts = Object.keys(counts).map((key) => {
            let values = key.split("/");
            let year = parseInt(values[0]);
            let month = parseInt(values[1]);
            return {
                path: key,
                label: `${year}年${month}月`,
                count: counts[key]
            }
        }).reverse();  

        return (
            <aside className="archive-monthly">
                <h1>月別アーカイブ</h1>
                <ul>
                {counts.map(date => (
                    <li key={date.index}>
                    <Link to={`/${date.path}/`}>
                        {date.label} ({date.count})
                    </Link>
                    </li>
                ))}
                </ul>
            </aside>
        )
    }}
  />
)

export default ArchiveMonthlyList